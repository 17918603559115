var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          attrs: {
            "title": "项目",
            "value": _vm.detail.projectName
          }
        }), _c('van-cell', {
          attrs: {
            "title": "房源",
            "value": _vm.detail.roomNo
          }
        })], 1), _c('van-cell-group', {
          staticClass: "cell-group",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "font-size": "15px"
                }
              }, [_vm._v("破底价信息")])];
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "placeholder": "请输入",
            "input-align": "right",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "field-label"
              }, [_vm._v("客户姓名")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.detail.customerName,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "customerName", $$v);
            },
            expression: "detail.customerName"
          }
        }), _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入",
            "input-align": "right",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "field-label"
              }, [_vm._v("客户手机号")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.detail.customerMobile,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "customerMobile", _vm._n($$v));
            },
            expression: "detail.customerMobile"
          }
        }), _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入",
            "input-align": "right",
            "required": ""
          },
          on: {
            "input": _vm.handlePeriodInput
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "field-label"
              }, [_vm._v("签约租期")])];
            },
            proxy: true
          }, {
            key: "extra",
            fn: function () {
              return [_c('span', {
                staticStyle: {
                  "padding-left": "10px"
                }
              }, [_vm._v("个月")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.detail.period,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "period", _vm._n($$v));
            },
            expression: "detail.period"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "付款方式",
            "value": _vm.filterPayMethod(_vm.detail.payMethod),
            "is-link": "",
            "required": ""
          },
          on: {
            "click": _vm.handleShowPayment
          }
        }), _c('van-cell', {
          attrs: {
            "title": "系统定价"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('a', {
                staticStyle: {
                  "color": "#356be8",
                  "text-decoration": "underline"
                },
                attrs: {
                  "href": "#"
                },
                on: {
                  "click": function ($event) {
                    $event.stopPropagation();
                    $event.preventDefault();
                    return _vm.handleShowPriceDetail.apply(null, arguments);
                  }
                }
              }, [_vm._v(_vm._s(_vm.detail.currentPrice || 0) + "元/月")])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "系统底价"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('span', [_vm._v(_vm._s(_vm.detail.minAmount || 0) + "元/月")])];
            },
            proxy: true
          }])
        }), _c('van-field', {
          attrs: {
            "type": "number",
            "placeholder": "请输入",
            "input-align": "right",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "field-label"
              }, [_vm._v("申请签约价")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.detail.approvalPrice,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "approvalPrice", _vm._n($$v));
            },
            expression: "detail.approvalPrice"
          }
        }), _c('van-field', {
          attrs: {
            "rows": "3",
            "autosize": "",
            "type": "textarea",
            "placeholder": "请输入",
            "maxlength": "2000",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('span', {
                staticClass: "field-label"
              }, [_vm._v("备注")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.detail.remark,
            callback: function ($$v) {
              _vm.$set(_vm.detail, "remark", $$v);
            },
            expression: "detail.remark"
          }
        })], 1), _c('p', {
          staticClass: "tips"
        }, [_vm._v("注意：申请通过后，需一个工作日内完成签约！")])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block field-apply",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleSubmit
          }
        }, [_vm._v(" 提交审批 ")])])])];
      },
      proxy: true
    }])
  }), _c('van-popup', {
    attrs: {
      "get-container": _vm.getContainer,
      "position": "bottom"
    },
    model: {
      value: _vm.rentalTypeInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.rentalTypeInfo, "show", $$v);
      },
      expression: "rentalTypeInfo.show"
    }
  }, [_c('van-picker', {
    attrs: {
      "columns": _vm.rentalTypeInfo.listData,
      "title": _vm.rentalTypeInfo.title,
      "item-height": "1.22667rem",
      "show-toolbar": ""
    },
    on: {
      "cancel": function ($event) {
        _vm.rentalTypeInfo.show = false;
      },
      "confirm": _vm.handleSelectRentalType
    }
  })], 1), _c('van-dialog', {
    attrs: {
      "confirmButtonColor": "#356be8"
    },
    model: {
      value: _vm.priceInfo.show,
      callback: function ($$v) {
        _vm.$set(_vm.priceInfo, "show", $$v);
      },
      expression: "priceInfo.show"
    }
  }, [_c('template', {
    slot: "title"
  }, [_vm._v(" " + _vm._s(_vm.priceInfo.title) + " ")]), _c('van-cell-group', {
    attrs: {
      "inset": ""
    }
  }, [_vm.priceInfo.data && _vm.priceInfo.data.layoutPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v("户型定价：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.layoutPrice)
  }, [_vm._v(_vm._s(_vm.priceFilter(_vm.priceInfo.data.layoutPrice)) + "元/月")])])]) : _vm._e(), _vm.priceInfo.data && _vm.priceInfo.data.floorPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v("楼层波动：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.floorPrice)
  }, [_vm._v(_vm._s(_vm.priceFilter(_vm.priceInfo.data.floorPrice)) + "元/月")])])]) : _vm._e(), _vm.priceInfo.data && _vm.priceInfo.data.orientationPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v("朝向波动：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.orientationPrice)
  }, [_vm._v(_vm._s(_vm.priceFilter(_vm.priceInfo.data.orientationPrice)) + "元/月")])])]) : _vm._e(), _vm.priceInfo.data && _vm.priceInfo.data.periodPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v(_vm._s(_vm.detail.period) + "个月租期：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.periodPrice)
  }, [_vm._v(_vm._s(_vm.priceFilter(_vm.priceInfo.data.periodPrice)) + "元/月")])])]) : _vm._e(), _vm.priceInfo.data && _vm.priceInfo.data.payPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v("付款方式：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.payPrice)
  }, [_vm._v(_vm._s(_vm.priceFilter(_vm.priceInfo.data.payPrice)) + "元/月")])])]) : _vm._e(), _vm.priceInfo.data && _vm.priceInfo.data.currentPrice ? _c('van-cell', [_c('div', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('p', [_vm._v("综合总价：")])]), _c('div', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('p', {
    class: _vm.priceColor(_vm.priceInfo.data.currentPrice)
  }, [_vm._v(_vm._s(_vm.priceInfo.data.currentPrice) + "元/月")])])]) : _vm._e()], 1)], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };