import _debounce from "lodash/debounce";
import { getApplyDetailApi, getApplyPriceDetailApi, setApplySubmitApi } from "@/api/priceBreak";
import common from '@mixins/common';
import filters from '@filters/filters';
import { formatPx2Rem } from '@utils/index';
import { Dialog } from 'vant';
import { mapGetters } from 'vuex';
export default {
  name: 'PriceBreakApply',
  mixins: [common, filters],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      houseId: '',
      detail: {},
      rentalTypeInfo: {
        // 付款方式modeOfPayment
        show: false,
        title: '请选择付款方式',
        listData: [{
          text: '月付',
          value: 1
        }, {
          text: '季付',
          value: 2
        }, {
          text: '半年付',
          value: 3
        }, {
          text: '年付',
          value: 4
        }, {
          text: '全额支付',
          value: 6
        }]
      },
      priceInfo: {
        show: false,
        title: '系统定价详情',
        data: {}
      }
    };
  },
  created() {},
  mounted() {
    const {
      token,
      projectId,
      projectName,
      userId,
      userName,
      houseId
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        dcProjectId: projectId,
        userId,
        userName,
        projectName
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        dcProjectId: projectId,
        projectName
      });
    }
    if (houseId) {
      this.houseId = houseId;
    }
    this.getDetail();
  },
  methods: {
    formatPx2Rem,
    getDetail() {
      this.$store.dispatch('base/SetLoading', true);
      getApplyDetailApi({
        houseId: this.houseId
      }).then(res => {
        if (res.data.code === '10000') {
          this.detail = res.data.data;
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    getPrice() {
      if (this.detail.period && this.detail.payMethod) {
        const query = {
          houseId: this.houseId,
          period: this.detail.period,
          payMethod: this.detail.payMethod
        };
        getApplyPriceDetailApi(query).then(res => {
          if (res.data.code === '10000') {
            this.$set(this.detail, 'currentPrice', res.data.data.currentPrice);
            this.$set(this.detail, 'minAmount', res.data.data.rentMinAmount);
            this.$set(this.priceInfo, 'data', res.data.data);
          }
        });
      }
    },
    handlePeriodInput: _debounce(function (value) {
      // 查询，防抖
      this.getPrice();
    }, 500),
    handleShowPayment() {
      this.rentalTypeInfo.show = true;
    },
    handleSelectRentalType(value) {
      this.rentalTypeInfo.show = false;
      this.$set(this.detail, 'payMethod', value.value);
      this.getPrice();
    },
    handleShowPriceDetail() {
      if (!this.detail.period) {
        this.$toast('请输入签约租期！');
        return;
      }
      if (!this.detail.payMethod) {
        this.$toast('请选择付款方式！');
        return;
      }
      this.priceInfo.show = true;
    },
    handleSubmit() {
      const {
        customerName,
        customerMobile,
        period,
        payMethod,
        approvalPrice,
        remark
      } = this.detail;
      if (!customerName) {
        this.$toast('请输入用户姓名!');
        return;
      }
      if (!customerMobile) {
        this.$toast('请输入手机号!');
        return;
      }
      if (!period) {
        this.$toast('请输入签约租期!');
        return;
      }
      if (!payMethod) {
        this.$toast('请选择付款方式!');
        return;
      }
      if (!approvalPrice) {
        this.$toast('请输入申请签约价!');
        return;
      }
      if (!remark) {
        this.$toast('请输入备注!');
        return;
      }
      this.$store.dispatch('base/SetLoading', true);
      const query = Object.assign({}, this.detail, {
        housekeeperId: this.userInfo.userId,
        housekeeper: this.userInfo.userName,
        houseId: this.houseId
      });
      setApplySubmitApi(query).then(res => {
        if (res.data.code === '10000') {
          Dialog.alert({
            message: '提交成功！',
            confirmButtonColor: '#356be8'
          }).then(() => {
            // on close
            this.$set(this.detail, 'customerName', '');
            this.$set(this.detail, 'customerMobile', '');
            this.$set(this.detail, 'period', '');
            this.$set(this.detail, 'payMethod', '');
            this.$set(this.detail, 'minAmount', 0);
            this.$set(this.detail, 'currentPrice', 0);
            this.$set(this.detail, 'approvalPrice', '');
            this.$set(this.detail, 'remark', '');
            this.$set(this.priceInfo, 'data', {});
          });
        } else {
          this.$toast.fail(res.data.msg);
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false);
      });
    },
    // 返回一个特定的 DOM 节点，作为挂载的父节点
    getContainer() {
      return document.querySelector('body');
    },
    filterPayMethod(value) {
      if (value) {
        return this.rentalTypeInfo.listData.find(item => item.value === value).text;
      }
    },
    priceColor(value) {
      let signValue = Math.sign(value);
      if (signValue === 1) {
        return 'red';
      } else if (signValue === -1) {
        return 'green';
      } else {
        return '';
      }
    },
    priceFilter(value) {
      let signValue = Math.sign(value);
      if (signValue === 1) {
        return `+${value}`;
      } else if (signValue === -1) {
        return `${value}`;
      } else {
        return '';
      }
    }
  }
};